import React, {
  FunctionComponent,
  useState,
  Fragment,
  useCallback,
  memo,
} from 'react';
import Link from 'next/link';
import { Container, Nav, NavItem, NavDropdown, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import NavLink from 'react-bootstrap/NavLink';
import Navbar from '@views/components/nav/Navbar';

import IconAlert from '@views/static/icon-alert.svg';
import IconPromotion from '@views/static/icon-tag.svg';
import IconShare from '@views/static/icon-share.svg';
import IconReport from '@views/static/report.svg';

import { Record } from 'immutable';
import { IUser } from '@server/data/models/user';
import { HEADER } from '../intl';

import { useLogoutMutation } from '../hooks/use-logout';
import { INTERNAL_ROUTES } from '@helpers/route';

interface Props {
  me: Record<IUser>;
}

const Avatar = ({ name }) => (
  <>
    <svg
      className="header__icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 55 55"
    >
      <path
        d="M55,27.5C55,12.337,42.663,0,27.5,0S0,12.337,0,27.5c0,8.009,3.444,15.228,8.926,20.258l-0.026,0.023l0.892,0.752
        c0.058,0.049,0.121,0.089,0.179,0.137c0.474,0.393,0.965,0.766,1.465,1.127c0.162,0.117,0.324,0.234,0.489,0.348
        c0.534,0.368,1.082,0.717,1.642,1.048c0.122,0.072,0.245,0.142,0.368,0.212c0.613,0.349,1.239,0.678,1.88,0.98
        c0.047,0.022,0.095,0.042,0.142,0.064c2.089,0.971,4.319,1.684,6.651,2.105c0.061,0.011,0.122,0.022,0.184,0.033
        c0.724,0.125,1.456,0.225,2.197,0.292c0.09,0.008,0.18,0.013,0.271,0.021C25.998,54.961,26.744,55,27.5,55
        c0.749,0,1.488-0.039,2.222-0.098c0.093-0.008,0.186-0.013,0.279-0.021c0.735-0.067,1.461-0.164,2.178-0.287
        c0.062-0.011,0.125-0.022,0.187-0.034c2.297-0.412,4.495-1.109,6.557-2.055c0.076-0.035,0.153-0.068,0.229-0.104
        c0.617-0.29,1.22-0.603,1.811-0.936c0.147-0.083,0.293-0.167,0.439-0.253c0.538-0.317,1.067-0.648,1.581-1
        c0.185-0.126,0.366-0.259,0.549-0.391c0.439-0.316,0.87-0.642,1.289-0.983c0.093-0.075,0.193-0.14,0.284-0.217l0.915-0.764
        l-0.027-0.023C51.523,42.802,55,35.55,55,27.5z M2,27.5C2,13.439,13.439,2,27.5,2S53,13.439,53,27.5
        c0,7.577-3.325,14.389-8.589,19.063c-0.294-0.203-0.59-0.385-0.893-0.537l-8.467-4.233c-0.76-0.38-1.232-1.144-1.232-1.993v-2.957
        c0.196-0.242,0.403-0.516,0.617-0.817c1.096-1.548,1.975-3.27,2.616-5.123c1.267-0.602,2.085-1.864,2.085-3.289v-3.545
        c0-0.867-0.318-1.708-0.887-2.369v-4.667c0.052-0.52,0.236-3.448-1.883-5.864C34.524,9.065,31.541,8,27.5,8
        s-7.024,1.065-8.867,3.168c-2.119,2.416-1.935,5.346-1.883,5.864v4.667c-0.568,0.661-0.887,1.502-0.887,2.369v3.545
        c0,1.101,0.494,2.128,1.34,2.821c0.81,3.173,2.477,5.575,3.093,6.389v2.894c0,0.816-0.445,1.566-1.162,1.958l-7.907,4.313
        c-0.252,0.137-0.502,0.297-0.752,0.476C5.276,41.792,2,35.022,2,27.5z"
      ></path>
    </svg>
    {name}
  </>
);
const TextWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const HeaderTopAuth: FunctionComponent<Props> = ({
  me,
}): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fetchLogout] = useLogoutMutation();
  const handleLogout = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await fetchLogout({
        update: (cache: any) => {
          // Update Local Data
          cache.writeData({
            data: {
              me: null,
              cart: null,
              orders: null,
              address: null,
            },
          });

          setIsSubmitting(false);
          // router.replace('/');
          location.href = '/';
        },
      });
    } catch (ex) {
      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  return (
    <Navbar top>
      <Container className="justify-content-end">
        <Nav>
          <NavItem>
            <Link href={INTERNAL_ROUTES.QUOTATION} passHref>
              <a className="nav-link active">
                <IconReport className="header__icon" />
                <span>Nhận báo giá văn phòng phẩm NHANH HƠN</span>
              </a>
            </Link>
          </NavItem>
          <NavItem>
            <a
              href="//vanphongphamabc.com/blog"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link active"
            >
              <IconShare className="header__icon" />
              {HEADER.SHARE}
            </a>
          </NavItem>
          <NavItem>
            <Link href="/bai-viet/chinh-sach-uu-dai">
              <a className="nav-link active">
                <IconPromotion className="header__icon" />
                {HEADER.PROMOTION}
              </a>
            </Link>
          </NavItem>
          <NavItem>
            <Link href={INTERNAL_ROUTES.NOTIFICATION} passHref>
              <NavLink active>
                <IconAlert className="header__icon" />
                {HEADER.NOTIFICATION}
              </NavLink>
            </Link>
          </NavItem>
          <NavDropdown
            id="basic-nav-dropdown"
            title={<Avatar name={me.get('name')} />}
            className="dropdown--account"
          >
            <Link href={INTERNAL_ROUTES.ACCOUNT} passHref>
              <NavDropdown.Item className="dropdown-item">
                {HEADER.MY_ACCOUNT}
              </NavDropdown.Item>
            </Link>
            <Link href={INTERNAL_ROUTES.MANAGE_ORDER} passHref>
              <NavDropdown.Item className="dropdown-item">
                {HEADER.ORDER}
              </NavDropdown.Item>
            </Link>
            <Link href={INTERNAL_ROUTES.INTEREST_PRODUCT} passHref>
              <NavDropdown.Item className="dropdown-item">
                {HEADER.INTEREST_PRODUCT}
              </NavDropdown.Item>
            </Link>
            <button
              className="dropdown-item dropdown-item--active"
              style={{ cursor: 'pointer' }}
              onClick={handleLogout}
              disabled={isSubmitting}
            >
              <TextWrapper>
                {isSubmitting ? (
                  <Fragment>
                    <Spinner
                      as="span"
                      animation="grow"
                      role="status"
                      aria-hidden="true"
                      size="sm"
                    />
                    &nbsp; {HEADER.LOGOUT}
                  </Fragment>
                ) : (
                  <Fragment>{HEADER.LOGOUT}</Fragment>
                )}
              </TextWrapper>
            </button>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default memo(HeaderTopAuth);
